.layoutStyle {
    min-height: 100vh !important;
}
.layoutStyle__header {
    padding: 0 !important;
    background-color: #ffffff !important;
}
/* .layoutStyle__content {
    margin: 0 16px !important;
} */
.layoutStyle__contentAuth {
    padding: 24 !important; 
    min-height: 360 !important;
}
.triggerButton {
    font-size: 20px;
    margin-left: 10px;
}
.layoutOnSiderCollapse {
    width: calc(100% - 0) !important;
    transition: all 0.01s 0.11s;
}

.layoutOnSiderExpand {
    width: calc(100% - 250px) !important;
    margin-left: 250px !important;
    transition: all 0.2s;
}