.invoice-order-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 160px; /* Adjust based on your layout needs */
}

.invoice-order-detail {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.invoice-order-text {
  margin-right: 5px;
  white-space: normal;
}

.copyable-text {
  max-width: 100%;
}
.financial-details-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 110px;
}

.financial-detail {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.financial-text {
  margin-right: 5px;
  white-space: normal;
}
