/* @media print { html, body { height: initial !important; overflow: initial !important; }} */
@media print {
    .ant-modal-content button{
        display: none;
    }
    .print_invoice{
        width: 90%;
        max-width: 1048px;
    }


    .print-btn{
        display: none;
    }
}


.footer_menu{
    font-weight: bold;
    color: black;
    margin-right: 2%;
}
.footer_menu_bar{
    text-align: right;
}
