.responsive-descriptions .ant-descriptions-item-label {
  white-space: nowrap; /* Prevents text from wrapping */
}

@media (max-width: 576px) { /* Adjust breakpoint as needed */
  .responsive-descriptions .ant-descriptions-item-label {
    font-size: 12px; /* Smaller font size on small devices */
  }
  .responsive-descriptions .ant-descriptions-item-content {
    font-size: 12px; /* Smaller content font size for consistency */
  }
}
