/* Basic document styles */
:root {
  color-scheme: dark;
}

// body {
//   box-sizing: border-box;
//   margin: 0;
//   background: #1d1d1d;
//   font-family: sans-serif;
//   line-height: 1.4;
//   color: #e6e6e6;
//   padding: 1rem;
//   min-height: 100vh;
// }

.editor {
  border: 1px solid gainsboro;
  padding: 5px;
}

.menu {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

button {
  background-color: green;
  color: rgba(255, 255, 255, 0.8); /* Updated RGBA value */
  border: 2px solid #1d1d1d;
  border-radius: 0.4em;
  transition: background-color 0.3s ease; /* Smooth transition */
}

button:hover {
  background-color: #006400; /* Updated RGBA value */
}

button:disabled {
  background-color: rgba(255, 255, 255, 0.1); /* Updated RGBA value */
  color: rgba(255, 255, 255, 0.2); /* Updated RGBA value */
}

button.is-active {
  background-color: #4caf50; /* Green background for active button */
  color: white;
  border-color: #388e3c; /* Darker green border */
}

/* Basic editor styles */
.tiptap {
  min-height: 70px;

  &:focus-visible {
    outline: none;
  }

  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background: rgba(150, 150, 150, 0.1);
    border: 1px solid rgba(100, 100, 100, 0.2);
    color: rgba(#000, 0.6);
    border-radius: 0.5rem;
    padding: 0.2rem;
  }

  pre {
    background: rgba(#ffffff, 0.1);
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
      border: none;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 3px solid rgba(13, 13, 13, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#ffffff, 0.1);
    margin: 2rem 0;
  }
}
.tippy-box button{
  background: white !important;
  border: none !important;
  border-radius: 0 !important;
  color: black !important;

}
